<div *ngIf="user?.profile" class="ui-block">
    <div class="ui-block-title">
        <h6 class="title" translate>profile.personal_info</h6>
    </div>
    <div class="ui-block-content">

        <div class="row" >
            <div class="col col-4 title">{{'profile.id_code' | translate }}:</div>
            <div class="col text">{{user.referenceId}}</div>
        </div>

        <div class="row" >
            <div class="col col-4 title">{{'profile.about_me' | translate }}:</div>
            <div class="col" [ngClass]="(showFullInfo)?'text':'text text-clamp'">{{user.profile.info}}</div>
        </div>

        <div class="row" >
            <div class="col col-4 title">{{'profile.age' | translate }}:</div>
            <div class="col text">{{getAge(user.profile.birthdate)}}</div>
        </div>

        <div class="row" *ngIf="user.profile.location">
            <div class="col col-4 title">{{'profile.place' | translate }}:</div>
            <div class="col text">{{user.profile.location.formatted_address}}</div>
        </div>
        <div class="row" *ngIf="user.profile.occupation && occupations">
            <div class="col col-4 title">{{'profile.occupation' | translate }}:</div>
            <div class="col text">{{getOccupation()}}</div>
        </div>
        <div class="row" *ngIf="user.profile.work">
            <div class="col col-4 title">{{'profile.work' | translate }}:</div>
            <div class="col text">{{user.profile.work}}</div>
        </div>
        <div class="row" >
            <div class="col col-4 title">{{'profile.joined' | translate }}:</div>
            <div class="col text">{{formatDate(user.created)}}</div>
        </div>

        <div class="row" >
            <div class="col col-4 title">{{'profile.web' | translate }}:</div>
            <div class="col text">
              <a [href]="user.profile | profileSocialLink: 'web': true" target="_blank">{{user.profile | profileSocialLink: 'web'}}</a>
            </div>
        </div>

        <div class="row" *ngIf="user.profile.emailPublic || isSuperAdmin">
          <div class="col col-4 title">{{'profile.email' | translate }}:</div>
          <div class="col text"><a href="emailto:{{user.email}}">{{user.email}}</a></div>
        </div>

        <div class="row" *ngIf="user.profile.phoneNumberPublic || isSuperAdmin">
          <div class="col col-4 title">{{'profile.phone' | translate }}:</div>
          <div class="col text"><a href="tel:{{ user.profile.phoneNumber | phone }}"></a><span>{{user.profile.phoneNumber | phone}}</span></div>
        </div>

        <div *ngIf="user.profile.facebook || user.profile.twitter || user.profile.instagram" class="widget w-socials">
            <h6 class="title">{{'profile.other_social' | translate }}:</h6>
            <a *ngIf="user.profile.facebook" target="_blank" [href]="user.profile | profileSocialLink: 'facebook'" class="social-item bg-facebook">
                <i class="fab fa-facebook-f" aria-hidden="true"></i>
                {{'facebook' | translate}}
            </a>
            <a *ngIf="user.profile.twitter" target="_blank" [href]="user.profile | profileSocialLink: 'twitter'" class="social-item bg-twitter">
                <i class="fab fa-twitter" aria-hidden="true"></i>
                {{'twitter' | translate}}
            </a>
            <a *ngIf="user.profile.linkedin" target="_blank" [href]="user.profile | profileSocialLink: 'linkedin'" class="social-item bg-linkedin">
              <i class="fab fa-linkedin" aria-hidden="true"></i>
              {{'linkedin' | translate}}
            </a>
            <a *ngIf="user.profile.instagram" target="_blank" [href]="user.profile | profileSocialLink: 'instagram'" class="social-item bg-instagram">
              <i class="fab fa-instagram" aria-hidden="true"></i>
              {{'instagram' | translate}}
            </a>
            <a *ngIf="user.profile.tiktok" target="_blank" [href]="user.profile | profileSocialLink: 'tiktok'" class="social-item bg-tiktok">
              <i aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"/>
                </svg>
              </i>
              <span>{{'tiktok' | translate}}</span>
            </a>
            <a *ngIf="user.profile.youtube" target="_blank" [href]="user.profile | profileSocialLink: 'youtube'" class="social-item bg-youtube">
              <i class="fab fa-youtube" aria-hidden="true"></i>
              {{'youtube' | translate}}
            </a>
        </div>

    </div>
</div>


<div *ngIf="org?.profile" class="ui-block">
    <div class="ui-block-title">
        <h6 class="title" translate>profile.org_personal_info</h6>
    </div>
    <div class="ui-block-content">

        <div class="row" >
            <div class="col col-4 title">{{'profile.about' | translate }}:</div>
            <div class="col" [ngClass]="(showFullInfo)?'text':'text text-clamp'">{{org.profile.info}}</div>
        </div>

        <div class="row" *ngIf="org.profile.location">
            <div class="col col-4 title">{{'profile.org_place' | translate }}:</div>
            <div class="col text">{{org.profile.location.formatted_address}}</div>
        </div>

        <div class="row" >
            <div class="col col-4 title">{{'profile.joined' | translate }}:</div>
            <div class="col text">{{formatDate(org.created)}}</div>
        </div>

        <div class="row" *ngIf="org.profile.phone">
            <div class="col col-4 title">{{'profile.contact_phone' | translate }}:</div>
            <div class="col text"><a href="tel:{{ org.profile.phone | phone }}">{{ org.profile.phone | phone }}</a></div>
        </div>

        <div class="row" *ngIf="org.profile.email">
            <div class="col col-4 title">{{'profile.contact_email' | translate }}:</div>
            <div class="col text"><a href="mailto:{{org.profile.email}}">{{org.profile.email}}</a></div>
        </div>

        <div class="row" >
            <div class="col col-4 title">{{'profile.web' | translate }}:</div>
            <div class="col text">
              <a [href]="org.profile | profileSocialLink: 'web': true" target="_blank">{{org.profile | profileSocialLink: 'web'}}</a>
            </div>
        </div>

        <div class="widget w-socials">
            <h6 class="title">{{'profile.other_social' | translate }}:</h6>
            <a *ngIf="org.profile.facebook" target="_blank" [href]="org.profile.facebook" class="social-item bg-facebook">
                <i class="fab fa-facebook-f" aria-hidden="true"></i>
                {{'facebook' | translate}}
            </a>
            <a *ngIf="org.profile.twitter" target="_blank" [href]="org.profile.twitter" class="social-item bg-twitter">
              <i class="fab fa-twitter" aria-hidden="true"></i>
              {{'twitter' | translate}}
            </a>
            <a *ngIf="org.profile.linkedin" target="_blank" [href]="org.profile.linkedin" class="social-item bg-linkedin">
              <i class="fab fa-linkedin" aria-hidden="true"></i>
              {{'linkedin' | translate}}
            </a>
            <a *ngIf="org.profile.instagram" target="_blank" [href]="org.profile.instagram" class="social-item bg-instagram">
              <i class="fab fa-instagram" aria-hidden="true"></i>
              {{'instagram' | translate}}
            </a>
            <a *ngIf="org.profile.tiktok" target="_blank" [href]="org.profile.tiktok" class="social-item bg-tiktok">
              <i aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"/>
                </svg>
              </i>
              <span>{{'tiktok' | translate}}</span>
            </a>
            <a *ngIf="org.profile.youtube" target="_blank" [href]="org.profile.youtube" class="social-item bg-youtube">
              <i class="fab fa-youtube" aria-hidden="true"></i>
              {{'youtube' | translate}}
            </a>
        </div>
    </div>
</div>
