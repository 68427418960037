import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { FilterType, HomeFilterComponent } from '../components/home-filter/home-filter.component';
import { ApiService } from '../services/api.service';
import { EventService } from '../services/event.service';
import { BaseEvent, EventType } from '../shared/types/base';
import { HomeFeedsComponent } from '../components/home-feeds/home-feeds.component';
import { Filter } from '../components/home-filter/types/types';
import { HomeMapComponent } from '../components/home-map/home-map.component';
import { GeocoderService } from '../services/geocoder.service';
import { NewFeedComponent } from '../components/new-feed/new-feed.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css'],
})
export class HomeComponent extends BaseComponent implements OnInit {
	filter: Filter = { countries: [], sports: [], tags: [] };

	currentTab: string = '';
	currentLang: string = 'en';
	filterType: FilterType = FilterType.Feeds;

	currentUser: any = null;
	collapseNewFeed: boolean = true;

	showMap: boolean = false;

	_feedsComponent?: HomeFeedsComponent = null;
	_geoCountryCode?: string;
	filtersLoaded = false;

	@ViewChild('feedsComponent') set feedsComponent(component: HomeFeedsComponent) {
		this._feedsComponent = component;
	}

	@ViewChild('newFeedComponent') newFeedComponent: NewFeedComponent;
	@ViewChild('filterComponent') filtersComponent: HomeFilterComponent;
	@ViewChild('homeMapComponent') homeMap: HomeMapComponent;

	get feedsComponent() {
		return this._feedsComponent;
	}

	constructor(
		protected activatedRoute: ActivatedRoute,
		eventService: EventService,
		private apiService: ApiService,
		private geoService: GeocoderService,
		private translateService: TranslateService,
	) {
		super(eventService);
	}

	ngOnInit(): void {
		this.geoService.countryCode.subscribe((code) => (this._geoCountryCode = code));
		this.currentUser = this.apiService.getCurrentUser();
		this.currentLang = this.translateService.currentLang;
		this.activatedRoute.params.subscribe((params) => {
			this.currentTab = params['tab'];
			this.showMap = params['map'] == 'map';

			this.currentTab
				? (this.filterType = this.currentTab as FilterType)
				: (this.filterType = FilterType.Home);
		});
	}

	protected eventReceived(event: BaseEvent): void {
		if (event.name === EventType.data && event.data.currentUser) {
			this.currentUser = this.apiService.getCurrentUser();
		}

		if (event.name === EventType.langChange && event.data.languageChanged) {
			this.currentLang = this.translateService.currentLang;
		}
	}

	getHeaderText() {
		if (!this.currentTab) return 'home.home';

		return `home.${this.currentTab}`;
	}

	filterChanged(event: { filter: Filter }): void {
		if (this.filterType === 'map') {
			this.homeMap?.search();
		}
		this.filter = Object.assign({}, event?.filter);
	}

	onFiltersLoaded(): void {
		this.filtersLoaded = true;
	}

	onNewFeedCollapse(collapsed: boolean): void {
		if (this.newFeedComponent) {
			collapsed ? this.newFeedComponent.blurFeedInput() : this.newFeedComponent.focusFeedInput();
		}
	}
}
